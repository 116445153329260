import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBRow,   
  MDBCol,   
} from 'mdb-react-ui-kit';


import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 


function Photogallery() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const images = [
    {
      src: 'img/photogallery/1.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/2.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/3.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/4.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/5.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/6.jpg',
      alt: 'Krane Station',
    },




    {
      src: 'img/photogallery/1b.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/2b.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/3b.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/4b.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/5b.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/6b.jpg',
      alt: 'Krane Station',
    },



    {
      src: 'img/photogallery/1c.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/2c.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/3c.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/4c.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/5c.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/6c.jpg',
      alt: 'Krane Station',
    },



    {
      src: 'img/photogallery/1d.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/2d.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/3d.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/4d.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/5d.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/6d.jpg',
      alt: 'Krane Station',
    },




    
  ];

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };





  const handleKeyDown = (event) => {
    if (modalShow) {
      if (event.keyCode === 37) {
        // Flecha izquierda
        handleCarouselPrev();
      } else if (event.keyCode === 39) {
        // Flecha derecha
        handleCarouselNext();
      }
    }
  };

  const handleCarouselPrev = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleCarouselNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalShow]);










  return (
    <div className='Photogallery'>

<div className='prizes-txt'>
        <h1>Gallery</h1>
        <h2>Some of our amazing prizes</h2>
      </div>


      <MDBRow className='g-2' id='margin'>
         {/* Primera columna */}
        
         <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
           <span onClick={() => handleImageClick(0)}>
             <img
               src='img/photogallery/1.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(1)}>
             <img
               src='img/photogallery/2-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(2)}>
             <img
               src='img/photogallery/3-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(3)}>
             <img
               src='img/photogallery/4.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(4)}>
             <img
               src='img/photogallery/5.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(5)}>
             <img
               src='img/photogallery/6-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>






         {/* x */}


         <MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
           <span onClick={() => handleImageClick(6)}>
             <img
               src= 'img/photogallery/1b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(7)}>
             <img
               src='img/photogallery/2b-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(8)}>
             <img
               src='img/photogallery/3b-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(9)}>
             <img
               src='img/photogallery/4b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(10)}>
             <img
               src='img/photogallery/5b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(11)}>
             <img
               src='img/photogallery/6b-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


{/* x */}



<MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
           <span onClick={() => handleImageClick(12)}>
             <img
               src='img/photogallery/1c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(13)}>
             <img
               src='img/photogallery/2c-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(14)}>
             <img
               src='img/photogallery/3c-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(15)}>
             <img
               src='img/photogallery/4c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(16)}>
             <img
               src='img/photogallery/5c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(17)}>
             <img
               src='img/photogallery/6c-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>







         <MDBCol lg={4} md={6} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(18)}>
             <img
               src= 'img/photogallery/1d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(19)}>
             <img
               src='img/photogallery/2d-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

      
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(20)}>
             <img
               src='img/photogallery/3d-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(21)}>
             <img
               src='img/photogallery/4d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(22)}>
             <img
               src='img/photogallery/5d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(23)}>
             <img
               src='img/photogallery/6d-vertical.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>





       </MDBRow>

       <Modal isOpen={modalShow} onRequestClose={handleCloseModal} style={{ 
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo oscuro semi-transparente
  },
  content: { 
    inset: 'auto',
    maxWidth: '800px', 
    margin: 'auto', // Centra el modal horizontalmente
    marginTop: '150px', // Ajusta la distancia desde la parte superior
    marginBottom: '150px', // Ajusta la distancia desde la parte inferior
  } 
}}>
  {/* Contenido del modal */}
  <Carousel selectedItem={selectedImageIndex || 0} showThumbs={false}>
    {images.map((image, index) => (
      <div key={index}>
        <img
          className="d-block w-100"
          src={image.src}
          alt={image.alt}
          style={{
            maxHeight: '700px', 
            objectFit: "contain",
            cursor: "pointer",
          }}
        />
      </div>
    ))}
  </Carousel>
  <button className='btn-modal' onClick={handleCloseModal}>
    Close
  </button>
</Modal>


      







    </div>
  );
}

export default Photogallery;










// import React, { useState, useEffect } from 'react';
// import {
//   MDBContainer,
//   MDBRow,   
//   MDBCol,   
// } from 'mdb-react-ui-kit';


// import Modal from 'react-modal';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; 


// function Photogallery() {
//   const [modalShow, setModalShow] = useState(false);
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     Modal.setAppElement('#root');
//   }, []);

//   const images = [
//     {
//       src: 'img/photogallery/img--38.jpg',
//       alt: 'Krane Station',
//     },
//     {
//       src: 'img/photogallery/img--45.jpg',
//       alt: 'Krane Station',
//     },
//     {
//       src:  'img/photogallery/img--37.jpg',
//       alt: 'Boat on Calm Water',
//     },
//     {
//       src: 'img/photogallery/img--30.jpg',
//       alt: 'Krane Station',
//     },
//     {
//       src: 'img/photogallery/img--40.jpg',
//       alt: 'Krane Station',
//     },
//     {

//       src: 'img/photogallery/img--31.jpg',
//       alt: 'Krane Station',
//     },





//     // {
//     //   src: 'img/photogallery/img--32.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //  src: 'img/photogallery/img--35.jpg',
//     //   alt: 'Krane Station',
//     // },
    

//     // {
//     //   src: 'img/photogallery/img----66.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/horizontal-05.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/horizontal-06.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img----64.jpg',
//     //   alt: 'Krane Station',
//     // },







//     // {
//     //   src: 'img/photogallery/img--46.jpg',
//     //   alt: 'Krane Station',
//     // },

//     // {
//     //   src: 'img/photogallery/img--13.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--15.jpg',
//     //   alt: 'Krane Station',
//     // },


//     // {
//     //   src: 'img/photogallery/img--14.jpg',
//     //   alt: 'Krane Station',
//     // },
    
    
//     // {
//     //   src: 'img/photogallery/img--16.jpg',
//     //   alt: 'Krane Station',
//     // },
    
//     // {
//     //   src: 'img/photogallery/img--17.jpg',
//     //   alt: 'Krane Station',
//     // },


    

//     {
//       src: 'img/photogallery/img--32.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--19.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--21.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--20.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--22.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--23.jpg',
//       alt: 'Krane Station',
//     },
    
//     // {
//     //   src: 'img/photogallery/img--23.jpg',
//     //   alt: 'Krane Station',
//     // },
    
//     {
//       src: 'img/photogallery/img--24.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--25.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--27.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--26.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--28.jpg',
//       alt: 'Krane Station',
//     },
    
//     {
//       src: 'img/photogallery/img--29.jpg',
//       alt: 'Krane Station',
//     },






    
//     // {
//     //   src: 'img/photogallery/img--30.jpg',
//     //   alt: 'Krane Station',
//     // },
    
//     // {
//     //   src: 'img/photogallery/img--31.jpg',
//     //   alt: 'Krane Station',
//     // },
    
//     // {
//     //   src: 'img/photogallery/img--33.jpg',
//     //   alt: 'Krane Station',
//     // },
    
//     // {
//     //   src: 'img/photogallery/img--32.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--34.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--35.jpg',
//     //   alt: 'Krane Station',
//     // },



    

//     // {
//     //   src: 'img/photogallery/img--36.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--37.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--41.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--38.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--40.jpg',
//     //   alt: 'Krane Station',
//     // },
//     // {
//     //   src: 'img/photogallery/img--43.jpg',
//     //   alt: 'Krane Station',
//     // },


//      {
//       src: 'img/photogallery/img--43.jpg',
//       alt: 'Krane Station',
//      },







    
    
//   ];

//   const handleImageClick = (index) => {
//     setSelectedImageIndex(index);
//     setModalShow(true);
//   };

//   const handleCloseModal = () => {
//     setModalShow(false);
//   };





//   const handleKeyDown = (event) => {
//     if (modalShow) {
//       if (event.keyCode === 37) {
//         // Flecha izquierda
//         handleCarouselPrev();
//       } else if (event.keyCode === 39) {
//         // Flecha derecha
//         handleCarouselNext();
//       }
//     }
//   };

//   const handleCarouselPrev = () => {
//     setSelectedImageIndex((prevIndex) =>
//       prevIndex > 0 ? prevIndex - 1 : images.length - 1
//     );
//   };

//   const handleCarouselNext = () => {
//     setSelectedImageIndex((prevIndex) =>
//       prevIndex < images.length - 1 ? prevIndex + 1 : 0
//     );
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [modalShow]);










//   return (
//     <div className='Photogallery'>

// <div className='prizes-txt'>
//         <h1>Gallery</h1>
//         <h2>Some of our amazing prizes</h2>
//       </div>


//       <MDBRow className='g-2' id='margin'>
//          {/* Primera columna */}
        
//          <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
//            <span onClick={() => handleImageClick(0)}>
//              <img
//                src='img/photogallery/img--38.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(1)}>
//              <img
//                src='img/photogallery/vertical--59.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Segunda columna */}
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(2)}>
//              <img
//                src='img/photogallery/vertical--63.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(3)}>
//              <img
//                src='img/photogallery/img--30.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Tercera columna */}
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(4)}>
//              <img
//                src='img/photogallery/img--40.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(5)}>
//              <img
//                src='img/photogallery/vertical--56.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>




// {/* 3 IMAGENEZ VERTICALES PARA USAR Q NO ESTAN REPETIDAS ACA.. */}





//          {/* x */}
// {/* 

//          <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize" >
//            <span onClick={() => handleImageClick(12)}>
//              <img
//                src='img/photogallery/img--46.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(13)}>
//              <img
//                src='img/photogallery/vertical--47.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

  
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(14)}>
//              <img
//                src='img/photogallery/vertical--48.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(15)}>
//              <img
//                src='img/photogallery/img--14.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

  
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(16)}>
//              <img
//                src='img/photogallery/img--16.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(17)}>
//              <img
//                src='img/photogallery/vertical--49.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol> */}



//          {/* x */}


//          <MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
//            <span onClick={() => handleImageClick(6)}>
//              <img
//                src= 'img/photogallery/img--32.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(7)}>
//              <img
//                src='img/photogallery/vertical--50.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Segunda columna */}
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(8)}>
//              <img
//                src='img/photogallery/vertical--51.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(9)}>
//              <img
//                src='img/photogallery/img--20.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Tercera columna */}
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(10)}>
//              <img
//                src='img/photogallery/img--22.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(11)}>
//              <img
//                src='img/photogallery/vertical--52.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>


// {/* x */}



// <MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
//            <span onClick={() => handleImageClick(12)}>
//              <img
//                src='img/photogallery/img--24.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(13)}>
//              <img
//                src='img/photogallery/vertical--53.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Segunda columna */}
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(14)}>
//              <img
//                src='img/photogallery/vertical--54.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(15)}>
//              <img
//                src='img/photogallery/img--26.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Tercera columna */}
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(16)}>
//              <img
//                src='img/photogallery/img--28.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(17)}>
//              <img
//                src='img/photogallery/vertical--55.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>







//          <MDBCol lg={4} md={6} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(6)}>
//              <img
//                src= 'img/photogallery/img--32.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(7)}>
//              <img
//                src='img/photogallery/vertical--58.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

      
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(8)}>
//              <img
//                src='img/photogallery/vertical-05.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(9)}>
//              <img
//                src='img/photogallery/horizontal-05.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>


//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(10)}>
//              <img
//                src='img/photogallery/horizontal-06.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(11)}>
//              <img
//                src='img/photogallery/vertical-06.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>


// {/* x */}

// {/* 
// <MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
//            <span onClick={() => handleImageClick(30)}>
//              <img
//                src='img/photogallery/img--30.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(31)}>
//              <img
//                src='img/photogallery/vertical--56.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

  
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(32)}>
//              <img
//                src='img/photogallery/vertical--57.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(33)}>
//              <img
//                src='img/photogallery/img--32.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>


//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(34)}>
//              <img
//                src='img/photogallery/img--34.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(35)}>
//              <img
//                src='img/photogallery/vertical--58.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol> */}


// {/* x */}

// {/* 

// <MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
//            <span onClick={() => handleImageClick(36)}>
//              <img
//                src='img/photogallery/img--36.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(37)}>
//              <img
//                src='img/photogallery/vertical--63.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

      
//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(38)}>
//              <img
//                src='img/photogallery/vertical--61.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(39)}>
//              <img
//                src='img/photogallery/img--38.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>


//          <MDBCol lg={4} className='img-mar' id="photoSize">
//            <span onClick={() => handleImageClick(40)}>
//              <img
//                src='img/photogallery/img--40.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(41)}>
//              <img
//                src='img/photogallery/vertical--60.jpg'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Krane Station'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>
//  */}


// {/* x */}



//        </MDBRow>

//        <Modal isOpen={modalShow} onRequestClose={handleCloseModal} style={{ content: { maxWidth: '800px', margin: 'auto', height: '600px' } }}>
//         <Carousel 
//         selectedItem={selectedImageIndex || 0} 
//         showThumbs={false} 
//         >
          
//           {images.map((image, index) => (
//             <div key={index}>
//               <img
//                 src={image.src}
//                 alt={image.alt}
//                 style={{
//                   maxWidth: '700px', 
//                   maxHeight: '700px', 
//                   width: 'auto',
//                   height: 'auto',
//                   margin: '0 auto',
//                 }}
//               />
//             </div>
//           ))}
//         </Carousel>
//         <button className='btn-modal' onClick={handleCloseModal}

//         >
//           Close
//           </button>
//       </Modal>
//     </div>
//   );
// }

// export default Photogallery;






