import React from 'react';
// import Carousel from './getAkraneHome';
import GAKraneCarousel from './GAK-homeCarousel';
import { Link } from 'react-router-dom';
import GetAKrane from './getAkrane';



// const estiloBanner = {
//   background: `url("img/blue-background.jpg") `,
//   backgroundSize: 'cover',
//   height: '110vh', 
//   justifyContent: 'center',
//   paddingTop: '90px', 
//   color: 'white',
//   textAlign: 'center',
// };

const GetAKraneHome = () => {
  return (
    <div  >
    <div className='estiloBanner' >
      <div className='GAK-Info'>
        <div>
          <h1 className='getAkrane'>GET A KRANE</h1>
          <h3 className='GAK-subtitle'>Are you a business owner seeking extra <span className="orange">passive income </span> <br />from an unused corner or wall?</h3>
          {/* <h3 className='GAK-subtitle'>Looking for a <span className="orange">passive income</span>?</h3> */}
          {/* <h3 className='GAK-subtitle'>Are you a part of the retail or hospitality industry?</h3>
          <h3 className='GAK-subtitle'>Looking for a <span className="orange">passive income</span>?</h3> */}
        </div>
        <div className='carouselHome'>
          <GAKraneCarousel />

        </div>
        <div>
            <h3 className='GAK-subtitle' id='marginTop'>Click in the link below to see more information in how to get your Krane</h3>
            <Link to='/getAkrane#getAkrane'> 
            <button className="SeeMore" >SEE MORE</button>
            </Link>
        </div>
    </div>
    </div>
    </div>
  );
};

export default GetAKraneHome;




