

import React from 'react';
// import Carousel from './getAkraneHome';
// import HAKraneCarousel from './HAK-homeCarousel';
import HAKraneCarousel from './HAK-HomeCarousel';
// import HireAKrane from './hireAkrane';
import { Link } from 'react-router-dom';
import HireAKrane from './hireAkrane';



const estiloBanner = {
  // background: `url("img/blue-background.jpg") `,
  backgroundSize: 'cover',
  // height: '90vh', 
  // display: 'flex',
  justifyContent: 'center',
  // paddingTop: '90px', 
  color: 'white',
  textAlign: 'center',
};

const HomeHireAKrane = () => {
  return (
    <div >
    <div style={estiloBanner} >
      <div className='GAK-Info'>
      <div>
        <h1 className='hireAkrane'>HIRE A KRANE</h1>
        <h3 className='HAK-subtitle'>Crane machines appeal to all ages and are a fantastic fun addition to any promotion, trade show, corporate event or party. </h3>

        {/* <h3 className='HAK-subtitle2'>Would you like to rent a Krane?</h3>
        <h3 className='HAK-subtitle'>We set everything up in 10 minutes, let the fun begin!</h3> */}

      </div>
<div className='carouselHome'>
      {/* <Carousel /> */}
      <HAKraneCarousel />
      </div>
      <h3 className='HAK-subtitle3' id='marginTop'>Do it today!</h3>
      <Link to='/hireAkrane'> 
      <button className="SeeMoreHire">SEE MORE</button>
    </Link>
    </div>
    </div>
    </div>
  );
};

export default HomeHireAKrane;




