import React, { useEffect } from 'react';
import HAKGallery from "./hireAkraneGallery";
import HAKraneForm from "./HAKraneForm";
import { Link } from 'react-router-dom';


const HireAKrane = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


// const hireAKrane = () => {
    return (
        
      <div className="container-getAkrane">
        <div className="getAkrane1">
        <div className="row getAkrane1">
          <div className="col-md-8 getAkrane1">
            <h2 className="getAkrane-Title">Hire a Toy Crane</h2>
            <h3 className="getAkrane-Subtitle">Create a memorable event with Kiwikrane!</h3>
            <p className="getAkrane-p">
            Crane toy machines appeal to all ages and are a fantastic fun addition to any promotion, trade show, corporate event or party. 
<br />As New Zealand's largest claw toy machine operator and supplier, 
Kiwikrane has the experience and skill tester machines to make 
your event a success.
<a href="#contactFormHAK2" data-replace="here"> <span className='prueba' style={{ color: '#CE316A' }}>Contact us</span></a> for a competitive quote and personalised service. </p>
          </div>
  
          {/* Columna de la derecha (2/4) */}
          <div className="col-md-4">
            <img
              src="img/krane.png"
              alt="Hire a Toy Claw Machine"
              className="img-krane"
            />
          </div>
        </div>
        </div>
        {/* <div  style={estiloBanner}> */}

        <HAKGallery />
        <div className="reason2">
            <div className="reason-getAkrane">
      <h1 className="getAkrane-reason">Your Perfect Event Partner</h1>
      <h2 className="getAkrane-reason2">Our cranes are an amazing crowd-pleasing attraction which can be filled with an almost endless variety of toys, giveaways or promotional items. </h2>
      <ul className='list-type'>
        <li className="item-reason">Knowing how stressful event planning can be, we take all the stress out of hiring a crane. </li>
        <li className="item-reason">Our cranes can be custom branded and personalised for your promotion or occasion </li>
        <li className="item-reason">Our cranes are delivered, electrically tested and set up onsite to your specific requirements by our skilled technicians.  </li>
        <li className="item-reason">We provide an emergency contact number if you have any queries during the hire. </li>
        <li className="item-reason">Our cranes can be hired for short or long periods and we can customise a hire package for you. </li>
        
        <p className="downLine">We take the hassle out of your promotion!</p>
        <p className="downLineY">We will be able to provide you a very competitive quote, amazing service and great claw machines. Please get in contact with us to help ensure the success of your next function.</p>
        
      </ul>
      {/* <Link to={{ pathname: '/', hash: '#contactFormHAK2' }}>
      <button className="SeeMoreReason">HIRE A KRANE</button>
    </Link> */}
    </div>
    </div>

 

    
    <div id="contactFormHAK2">
    <HAKraneForm />
    </div>
    
      </div>
    );
  };
  
  export default HireAKrane;









  