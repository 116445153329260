import React, { useRef, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const images = [
  { id: 1, src: "img/GAK-gallery/01.jpg", alt: "Kiwi Krane" },
  { id: 3, src: "img/GAK-gallery/02.jpg", alt: "Kiwi Krane" },
  { id: 4, src: "img/GAK-gallery/03.jpg", alt: "Kiwi Krane" },
  { id: 5, src: "img/GAK-gallery/04.jpg", alt: "Kiwi Krane" },
  { id: 6, src: "img/GAK-gallery/05.jpg", alt: "Kiwi Krane" },
  { id: 7, src: "img/GAK-gallery/06.jpg", alt: "Kiwi Krane" },
];

const GAKraneCarousel = () => {
  const splideRef = useRef(null);

  useEffect(() => {
    const splide = splideRef.current.splide;

    splide.on("beforeMove", (newIndex) => {
      const centerIndex = Math.floor(images.length / 2);
      const scale = newIndex === centerIndex ? 1.2 : 1;
      const centerSlide = splide.Components.Elements.slides[centerIndex];
      centerSlide.style.transform = `scale(${scale})`;
      centerSlide.style.transition = "transform 0.5s ease"; 
    });

    splide.on("afterMove", () => {
      const centerIndex = Math.floor(images.length / 2);
      const centerSlide = splide.Components.Elements.slides[centerIndex];
      centerSlide.style.transform = "scale(1)";
      centerSlide.style.transition = "none"; 
    });
  }, []);

  return (
    <div className="collage-section" id="brand" style={{paddingRight: '15px' }}>
      <Splide
        className="splide"
        options={{
          type: "loop",
          perPage: 3,
          focus: "center",
          autoplay: true,
          interval: 2700,
          height: "auto",
          marginRight: "100px",
          pagination: false,
          arrows: false,
        }}
        ref={splideRef}
      >
        {images.map((image, index) => (
          <SplideSlide key={index}>
            <img src={image.src} alt={image.alt} className="carousel-home" style={{ borderRadius: '10px' }}  />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default GAKraneCarousel;

