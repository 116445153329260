import React from 'react';
import { Link } from 'react-router-dom';
import Prizes from './prizes';

const HomeGallery = () => {
  return (
    <section className='HomeGallery' >
        <h1 className='gallery-title'>GALLERY</h1>
    <div style={{ maxWidth: '1000px', margin: '0 auto'}}>
      
      <div style={{ display: 'flex', flexWrap: 'wrap' }} id='gallery'>
        <div style={{ width: '33.33%', padding: '5px' }}>
          <img
            src="img/getAKrane/photo1.jpg"
            alt="Imagen 1"
            style={{ width: '100%', borderRadius: '8px' }}
            className='HomeGalleryPhoto'
          />
        </div>
        <div style={{ width: '33.33%', padding: '5px' }}>
          <img
            src="img/getAKrane/photo2.jpg"
            alt="Imagen 2"
            style={{ width: '100%', borderRadius: '8px' }}
            className='HomeGalleryPhoto'
          />
        </div>
        <div style={{ width: '33.33%', padding: '5px' }}>
          <img
            src="img/getAKrane/photo3.jpg"
            alt="Imagen 3"
            style={{ width: '100%', borderRadius: '8px' }}
            className='HomeGalleryPhoto'
          />
        </div>
        <div style={{ width: '33.33%', padding: '5px' }}>
          <img
            src="img/getAKrane/photo4.jpg"
            alt="Imagen 4"
            style={{ width: '100%', borderRadius: '8px' }}
            className='HomeGalleryPhoto'
          />
        </div>
        <div style={{ width: '33.33%', padding: '5px' }}>
          <img
            src="img/getAKrane/photo5.jpg"
            alt="Imagen 5"
            style={{ width: '100%', borderRadius: '8px' }}
            className='HomeGalleryPhoto'
          />
        </div>
        <div style={{ width: '33.33%', padding: '5px' }}>
          <img
            src="img/getAKrane/photo6.jpg"
            alt="Imagen 6"
            style={{ width: '100%', borderRadius: '8px' }}
            className='HomeGalleryPhoto'
          />
        </div>

        <Link to='/prizes'> 
      <button className='SeeMoreGallery' style={{ float: 'right' }}>SEE MORE</button>
        </Link>
      </div>

 
    </div>
    </section>
  );
};

export default HomeGallery;
