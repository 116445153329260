import React from 'react';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-columns'>
        {/* Columna 1: Lista de enlaces */}
        <div className='footer-column'>
          <h2 className='footer-title'>Links</h2>
          <ul className='list-unstyled links-container' id='footer-links'>
            <li>
              <a className='links-white' href="/">• Home</a>
            </li>
            <li>
              <a className='links-white' href="./getAkrane">• Get a Toy Crane</a>
            </li>
            <li>
              <a className='links-white' href="./hireAkrane">• Hire a Toy Crane</a>
            </li>
            <li>
              <a className='links-white' href="./prizes">• Prizes Gallery</a>
            </li>
            <li>
              <a className='links-white' href="./about">• About us</a>
            </li>
            <li>
              <a className='links-white' href="./contact">• Contact us</a>
            </li>
          </ul>
        </div>

        {/* Columna 2: Logos */}
        <div className='footer-column2'>
          <h2 className='footer-title'>Also Visit</h2>
          <a href="https://www.kranestation.co.nz" target="_blank">
            <img src="img/footer/ks.png" className='footer-logos' alt="Krane Station" />
          </a>
          <a href="https://www.ballsnallvending.co.nz/" target="_blank">
            <img src="img/footer/bNa.png" className='footer-logos' alt="Ball N All" />
          </a>
          <a href="https://www.laundrymates.co.nz/" target="_blank">
            <img src="img/footer/LM.png" className='footer-logos' alt="Laundry Mate" />
          </a>
        </div>

        {/* Columna 3: Logos de redes sociales */}
        <div className='footer-column2'>
          <h2 className='footer-title'>Social Media</h2>
          <a href="https://www.instagram.com/kiwikrane/?hl=en" target="_blank">
            <img src="img/footer/insta.png" className='footer-logos2' alt="kiwikrane" />
          </a>
          <a href="https://www.facebook.com/kiwikrane/" target="_blank">
            <img src="img/footer/face.png" className='footer-logos2' alt="kiwikrane" />
          </a>
        </div>
      </div>
      <div className='text-center p-3' style={{ backgroundColor: '#1C1C7F', color: 'white' }}>
        Website crafted by <a href="https://www.natalilarroza.com" target="_blank" style={{ color: 'white', textDecoration:"none", fontWeight: "bold" }}> Natali Larroza </a>| &copy; {new Date().getFullYear()} Kiwikrane
      </div>
      {/* <div className='text-center p-3' style={{ backgroundColor: '#275DA6', color: 'white' }}>
  Website crafted by <a href="https://www.natalilarroza.com" target="_blank" style={{ color: 'white', textDecoration: 'underline' }}>Natali Larroza</a> | &copy; {new Date().getFullYear()} Kiwikrane
</div> */}
    </div>
  );
};

export default Footer;





// import React from 'react';

// const Footer = () => {
//   return (
//     <div className='footer'>
//       <div style={{ display: 'flex', maxWidth: '1000px', marginRight: 'auto', marginLeft: 'auto', height: 'auto' }}>
  
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h2 className='footer-title'>Links</h2>
//           <ul className='list-unstyled d-flex justify-content-center flex-wrap links-container text-center' style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//             <li>
//               <a className='links-white' href="/">Home</a>
//             </li>
//             <li>
//               <a className='links-white' href="./getAkrane">Get a Krane</a>
//             </li>
//             <li>
//               <a className='links-white' href="./hireAkrane">Hire a Krane</a>
//             </li>
//             <li>
//               <a className='links-white' href="./prizes">Pirzes Gallery</a>
//             </li>
//             <li>
//               <a className='links-white' href="./contact">About us</a>
//             </li>
//           </ul>
//         </div>


//         <div style={{ flex: 1, padding: '10px' }}>
//           <h2 className='footer-title'>Visit also</h2>
//           <a href="https://www.kranestation.co.nz" target="_blank">
//             <img src="img/footer/ks.png" className='footer-logos' alt="Krane Station" style={{ width: '100%' }} />
//           </a>
//           <a href="https://www.ballsnallvending.co.nz/" target="_blank">
//             <img src="img/footer/bNa.png" className='footer-logos' alt="Ball N All" style={{ width: '100%' }} />
//           </a>
//           <a href="https://www.laundrymates.co.nz/" target="_blank">
//             <img src="img/footer/LM.png" className='footer-logos' alt="Laundry Mate" style={{ width: '100%' }} />
//           </a>
//         </div>

  
//         <div style={{ flex: 1, padding: '10px' }}>
//           <h2 className='footer-title'>Social Media</h2>
//           <a href="https://www.instagram.com/kiwikrane/?hl=en" target="_blank">
//             <img src="img/footer/insta.png" className='footer-logos2' alt="Instagram" style={{ width: '100%' }} />
//           </a> <br />
//           <a href="https://www.facebook.com/kiwikrane/" target="_blank">
//             <img src="img/footer/face.png" className='footer-logos2' alt="Facebook" style={{ width: '100%' }} />
//           </a>
//         </div>
//       </div>
//       <div className='text-center p-3' style={{ backgroundColor: '#1C1C7F', color: 'white' }}>
//         Website crafted by Natali Larroza | &copy; {new Date().getFullYear()} Kiwikrane
//       </div>
//     </div>
//   );
// };

// export default Footer;
