import React, { useEffect } from 'react';
import GAKGallery from './getAkrane-gallery';
import GAKraneForm from './getAkrane-form';

const GetAKrane = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

// const GetAKrane = () => {
    return (
        
      <div className="container-getAkrane" >
        <div className="getAkrane1">
        <div className="row getAkrane1">
          {/* Columna de la izquierda (2/4) */}
          <div className="col-md-8 getAkrane12">
            <h2 className="getAkrane-Title">Get a toy krane</h2>
            <h3 className="getAkrane-Subtitle">Revenue Share With Kiwikrane</h3>
            <p className="getAkrane-p">
            Kiwikrane is New Zealand's largest amusement crane toy machine 
operator. We have been claw toy machines since 1999 and have 
franchise areas covering all of New Zealand.
<br />We operate on a revenue share basis with venues and are the industry experts in what we do.
<br />Our eye catching and popular skill tester machines appeal to people
of all ages. The prizes range from cartoon and movie products 
through to the biggest and best sporting brands in New 
Zealand.  <span style={{ color: '#CE316A' }}><a href="#contactForm"> Join us now.</a></span>
            </p>
          </div>
  
          {/* Columna de la derecha (2/4) */}
          <div className="col-md-4">
            <img
              src="img/krane.png"
              alt="get a toy crane"
              className="img-krane"
            />
          </div>
        </div>
        </div>
        {/* <div  style={estiloBanner}> */}
          <div className="reason">
            <div className="reason-getAkrane">
              <div className="marginLR">
      
              <h1 className="getAkrane-reason">Why Kiwikrane <br />Is The Best Choice</h1>
      {/* <h2 className="getAkrane-reason2">Why Kiwikrane Is The Best Choice</h2> */}
      <ul className='list-type'>
        <li className="item-reason">Together we can turn unused space into revenue making space.</li>
        <li className="item-reason">Our crane machines are stocked with a wide range of licenced and superior generic product.</li>
        {/* <li className="item-reason">We are the official licenses for NRL, NZRU, Disney, Justice League, Avengers and the latest movie plush.</li> */}
        <li className="item-reason">We regularly visit, stock and service our claw machines keeping your customers happy and regularly winning prizes.</li>
        <li className="item-reason">All our skill tester machines use non-resettable mechanical meters to record money in and prizes out.</li>
        <li className="item-reason">All machines have a customer service number to call if there are any issues. We deal with all issues promptly.</li>
        <li className="item-reason">All our sited machines are electrically certified (Test & Tagged) to AS/NZS 3760 standard.</li>
        <li className="item-reason">We also hold current public liability insurance of $10,000,000.</li>
        {/* <li className="item-reason">Try it risk-free; we'll remove it if you're not satisfied.</li> */}

        {/* <h1 className="getAkrane-reason">9 Fantastic Reasons</h1>
      <h2 className="getAkrane-reason2">to Get a Kiwikrane at Your Location:</h2>
      <ul className='list-type'>
        <li className="item-reason">Earn extra funds effortlessly.</li>
        <li className="item-reason">We cover all machine costs and pay you.</li>
        <li className="item-reason">Attract customers with high-quality prizes.</li>
        <li className="item-reason">Maximize unused space for extra income.</li>
        <li className="item-reason">We handle all maintenance, ensuring a stunning machine.</li>
        <li className="item-reason">Our promotions keep customers coming back.</li>
        <li className="item-reason">Regular wins boost loyalty and your business image.</li>
        <li className="item-reason">Top-quality products, including the latest characters.</li>
        <li className="item-reason">Try it risk-free; we'll remove it if you're not satisfied.</li> */}
      </ul>
      {/* <button className="SeeMoreReason">SEE MORE</button> */}
    </div>
    </div>
    </div>

    <GAKGallery />
    <div id="contactForm">
    <GAKraneForm />
    </div>
      </div>
    );
  };
  
  export default GetAKrane;




