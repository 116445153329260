import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    email_id: '',
    message: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error('Please complete the reCAPTCHA.');
      return;
    }

    // Your EmailJS configuration
    const serviceId = 'service_dtrqxk5';
    const templateId = 'template_zj1h2jp';
    const userId = 'LpwagaB2zdxcjzRVW';

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        // Reset the form after successful submission
        setFormData({
          first_name: '',
          email_id: '',
          message: '',
        });
        setRecaptchaValue(null);
        recaptchaRef.current.reset();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div className='contact' id="Contact">
      
      <div className='ContactUs-container'>
        
      <div className='contact-container'>
        
        <h1 className='contact-title'>CONTACT US</h1>
        <div className='contact-txt'>
          <p>Please don't hesitate to send us a message if you have any questions. We are here to help and would be delighted to assist you with any inquiries you may have.</p>
        </div>

        
        <div className="contactUs" id="contacto">
          <div className='registerForm'>
            <div className='formApply'>
              <Form onSubmit={handleSubmit} id='formApllyContact'>
                <Form.Group className="mb-4" controlId="first_name">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    style={{ fontSize: '20px' }}
                    // fontSize='20px'
                    // placeholder="Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="email_id">
                  <Form.Label>Email:</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                      type="email"
                      style={{ fontSize: '20px' }}
                      // placeholder="Email"
                      aria-describedby="inputGroupPrepend"
                      required
                      name="email_id"
                      value={formData.email_id}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="message">
                  <Form.Label>Message:</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      type="text"
                      style={{ fontSize: '20px' }}
                      name="message"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <div className='d-flex justify-content-end'>
                  <ReCAPTCHA sitekey='6Le8WicpAAAAALhfy0WdYOliGf5E_8eKFEEDffSy' onChange={handleRecaptchaChange} />
                </div>

                <div className='btnForm' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button id="btnForm" type="submit">Send</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    
  );
};
export default Contact;
