import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export const GAKraneForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    phone:'',
    email_id: '',
    company_name: '',
    location:'',
    message: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error('Please complete the reCAPTCHA.');
      return;
    }

    // Your EmailJS configuration


    const serviceId = 'service_sc3n7ma';
    const templateId = 'template_undlfas';
    const userId = '2bmDb9D-p5ROkt5xX';

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        // Reset the form after successful submission
        setFormData({
          first_name: '',
          phone:'',
          email_id: '',
          company_name:'',
          location:'',
          message: '',
        });
        setRecaptchaValue(null);
        recaptchaRef.current.reset();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div className='contact' id="Contact">
      
      <div className='ContactUs-container'>
        
      <div className='contact-container'>
        
        <h1 className='GAKraneForm-title'>CONTACT US</h1>
        <div className='GAKraneForm-subtitle'>
          <p>Please don't hesitate to send us a message if you have any questions. We are here to help and would be delighted to assist you with any inquiries you may have.</p>
        </div>

        
        <div className="contactUs" id="GAKrane-Form">
          <div className='registerForm'>
            <div className='formApply'>
              <Form onSubmit={handleSubmit} className='GAKrane-Form'>
                
                <Form.Group className="mb-4" controlId="first_name">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                  autoComplete="given-name"
                    required
                    type="text"
                    style={{ fontSize: '20px' }}
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="phone">
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control
                  autoComplete="tel"
                    required
                    type="tel"
                    style={{ fontSize: '20px' }}
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    pattern="[0-9]*"
                    required
                    style={{ fontSize: '20px' }}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="email_id">
                  <Form.Label>Email:</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                     autoComplete="email"  
                      type="email"
                      style={{ fontSize: '20px' }}
                      // placeholder="Email"
                      aria-describedby="inputGroupPrepend"
                      required
                      name="email_id"
                      value={formData.email_id}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="company_name">
                  <Form.Label>Company Name:</Form.Label>
                  <Form.Control
                  autoComplete="organization" 
                    required
                    type="text"
                    style={{ fontSize: '20px' }}
                    required
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="location">
                  <Form.Label>Company location:</Form.Label>
                  <Form.Control
                  autoComplete="address-level2" 
                    required
                    type="text"
                    style={{ fontSize: '20px' }}
                    required
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </Form.Group>




                <Form.Group className="mb-4" controlId="message">
                  <Form.Label>Message:</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                    autoComplete="off"  
                      as="textarea"
                      rows={3}
                      type="text"
                      style={{ fontSize: '20px' }}
                      name="message"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <div className='d-flex justify-content-end'>
                  <ReCAPTCHA sitekey='6Le8WicpAAAAALhfy0WdYOliGf5E_8eKFEEDffSy' onChange={handleRecaptchaChange} />
                </div>

                <div className='btnForm' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button id="btnForm" type="submit">Send</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    
  );
};


export default GAKraneForm;







// import React, { useState, useRef } from 'react';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Button from 'react-bootstrap/Button';
// import emailjs from 'emailjs-com';
// import ReCAPTCHA from "react-google-recaptcha";

// export const GAKraneForm = () => {
//   const [formData, setFormData] = useState({
//     first_name: '',
//     email_id: '',
//     message: '',
//     phone: '',
//     company_name: '',
//     company_location:'',
//   });

//   const [recaptchaValue, setRecaptchaValue] = useState(null);
//   const recaptchaRef = useRef(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleRecaptchaChange = (value) => {
//     setRecaptchaValue(value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!recaptchaValue) {
//       console.error('Please complete the reCAPTCHA.');
//       return;
//     }

//     // Your EmailJS configuration
//     const serviceId = 'service_up7eara';
//     const templateId = 'template_r446xxq';
//     const userId = '4S9SBuGXNl2oJO-Xa';

//     // Send the email using EmailJS
//     emailjs.send(serviceId, templateId, formData, userId)
//       .then((response) => {
//         console.log('Email sent successfully:', response);
//         // Reset the form after successful submission
//         setFormData({
//           first_name: '',
//           email_id: '',
//           message: '',
//           phone: '',
//           company_name:'',
//           company_location:'',
          
//         });
//         setRecaptchaValue(null);
//         recaptchaRef.current.reset();
//       })
//       .catch((error) => {
//         console.error('Error sending email:', error);
//       });
//   };

//   return (
//     <div className='contact' id="Contact">
//       <div className='Contact-container'>
//         <h1 className='GAKraneForm-title'>CONTACT US</h1>
//         <p className='GAKraneForm-subtitle'>
//           Don't hesitate to send us a message if you have any questions. We are here to help and would be delighted to assist you with any inquiries you may have.
//         </p>

        
//         <div  id="GAKrane-Form">
//           <form className="GAKrane-Form" onSubmit={handleSubmit}>

//             <div className="mb-3">
//               <label htmlFor="first_name" className="form-label">Name:</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="first_name"
//                 name="first_name"
//                 value={formData.first_name}
//                 onChange={handleChange}
//                 required
//                 style={{ fontSize: '20px' }}
//               />
//             </div>


//             <div className="mb-3">
//   <label htmlFor="phone" className="form-label">Phone Number:</label>
//   <input
//     type="tel" // Cambiamos el tipo a "tel"
//     className="form-control"
//     id="phone"
//     name="phone"
//     value={formData.phone}
//     onChange={handleChange}
//     pattern="[0-9]*"
//     required
//     style={{ fontSize: '20px' }}
//   />
// </div>

//             <div className="mb-3">
//               <label htmlFor="email_id" className="form-label">Email:</label>
//               <InputGroup>
//                 <span className="input-group-text" id='inputGroupPrepend' style={{ fontSize: '20px' }}>@</span>
//                 <input
//                   type="email"
//                   className="form-control"
//                   id="email_id"
//                   name="email_id"
//                   value={formData.email_id}
//                   onChange={handleChange}
//                   required
//                   style={{ fontSize: '20px' }}
//                 />
//               </InputGroup>
//             </div>





//             <div className="mb-3">
//               <label htmlFor="company_name" className="form-label">Company Name:</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="company_name"
//                 name="company_name"
//                 value={formData.company_name}
//                 onChange={handleChange}
//                 required
//                 style={{ fontSize: '20px' }}
//               />
//             </div>


//             <div className="mb-3">
//               <label htmlFor="company_location" className="form-label">Company Location:</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="company_location"
//                 name="company_location"
//                 value={formData.company_location}
//                 onChange={handleChange}
//                 required
//                 style={{ fontSize: '20px' }}
//               />
//             </div>

//             <div className="mb-3">
//               <label htmlFor="message" className="form-label">Message:</label>
//               <textarea
//                 rows="3"
//                 className="form-control"
//                 id="message"
//                 name="message"
//                 value={formData.message}
//                 onChange={handleChange}
//                 required
//                 style={{ fontSize: '20px' }}
//               />
//             </div>

//             <div className="d-flex justify-content-end">
//               <ReCAPTCHA sitekey='6LdT26woAAAAABg64esaHXXxC8E2BoRtrhl_0mOV' onChange={handleRecaptchaChange} />
//             </div>

//             <div className='btn' style={{ display: 'flex', justifyContent: 'flex-end' }}>
//               <Button id="btnForm" type="submit">Send</Button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GAKraneForm;
