import React from "react";
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";

export let banner_data = [
  {
    id: 1,
    img: "img/Top Banner-01.jpg",
  },
  {
    id: 2,
    img: "img/Top Banner-02.jpg",
    link: "/getAkrane",
  },
  {
    id: 3,
    img: "img/Top Banner-03.jpg",
    link: "/hireAkrane",
  },
];

function Banner() {
    return (
        <div>
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        {banner_data.map((item) => (
          <a href={item.link} key={item.id} className="banner-img">
            <img src={item.img} className="imgbanner" alt={`Kiwikrane ${item.id}`} />
          </a>
        ))}
      </Carousel>

      <div className="banner">
        <img src="img/Logo-top.png" alt="Kiwi Krane" className="Logo-top"/>

      </div>


<h5 className="intro">
Kiwikrane is an entertainment business specialising in amusement crane (claw) machines.  We have franchisee’s covering all New Zealand.   Our franchisees place machines in a variety of locations, are well trained and ensure the machines are regularly maintained, visited, and stocked with a variety of quality plush toys, balls, sports products and other prizes. More  
 <span style={{ color: '#CE316A' }}><a href="./about" className="a-link"> about us.</a></span>
</h5>

</div>
    );
  }

  export default Banner;