import React from 'react';

const About = () => {
  return (
    <div className='about'  >
      <div className='aboutUs-txt'>
        <h1>About us</h1>
        <h2 style={{ lineHeight: '1.3' }}>
        Since 1999, Kiwikrane has operated a nationwide franchise system of claw machines, 
        supporting owner-operators as they operate skill tester and instant prize machines on a 
        revenue-share basis with other businesses in their local area. 
        This franchise was also successfully exported to Australia as Koalakrane. 
  <p style={{ marginTop: '20px' }}>
  Kiwikrane is also an agent for Intercard payment and points systems for arcades, 
  which allows venues to operate a cashless card-based system to run their arcades. 
  We are also involved in vending with a focus on laundry products and locations. 
  </p>
  <p style={{ marginBottom: '20px' }}>
  Kiwikrane specializes in importing licensed and generic plush for crane machines and also stocks a huge range of products for arcade redemption counters or prize dispensers. We also support bulk vending with a huge range of bouncy balls. 
   
  </p>
  <p style={{ marginBottom: '20px' }}>
  Although there haven't been greenfield franchise areas for sale in NZ for many years due to complete coverage, every so often one of our established territories comes up for sale, or we can approach a franchisee on your behalf. If you would like more information about anything we do, please make contact <a className='decorationNone' href="/contact" > <span  style={{ color: '#CE316A' }}>contact</span></a>
  </p>
</h2>


      </div>
      <div className='iconos'>
        {/* <div style={{ display: 'flex', justifyContent: 'center', marginRight:'20px', marginLeft:'20px', }}>
          <div>
            <img src="img/about/1.jpg" alt="Kiwi krane" className='image' />
            <p className='align'>ASSISTANCE WITH PARTS <br /> AND TECHNICAL SUPPORT.</p>
          </div>
          <div>
            <img src="img/about/2.jpg" alt="Kiwi krane"className='image' />
            <p className='align'>ASSISTANCE WITH PRIZES <br />FOR THE MACHINES.</p>
          </div>
          
          <div>
            <img src="img/about/3.jpg" alt="Kiwi krane"className='image' />
            <p className='align'>LICENCES <br />SUCH AS ALL BLACKS</p>
          </div>
        </div> */}
   

        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <img src="img/about/4.jpg" alt="Kiwi krane" className='image' />
            <p className='align'>SOFTWARE FOR <br />MANAGING THE MACHINES</p>
          </div>
          <div>
            <img src="img/about/5.jpg" alt="Kiwi krane" className='image' />
            <p className='align'>NATIONAL CONTRACTS</p>
          </div>
          <div>
            <img src="img/about/6.jpg" alt="Kiwi krane" className='image' />
            <p className='align'>BUSINESS ADVISE</p>
          </div>
        </div> */}

      <div style={{ display: 'flex', justifyContent: 'center', marginRight:'20px', marginLeft:'20px', }}>
        {/* 2 imágenes con descripciones al lado */}
        <div>
          <img src="img/about/7.jpg" alt="Kiwi krane" className='image'  />
          <p className='align'>Over 1000 machines and 50 Franchise Areas in New Zealand.</p>
        </div>
        <div>
          <img src="img/about/8.jpg" alt="Koala Krane" className='image'  />
          <p className='align'>Over 1000 machines and 100 Franchise Territories in Australia</p>
        </div>
      </div>
      </div>

      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p>Descripción 1</p>
        <p>Descripción 2</p>
      </div> */}
    </div>
  );
};

export default About;
